<template>
  <Page
    :mounted.sync="mounted"
  >
    <template v-slot:title>
      <v-icon class="mr-2">
        mdi-table-account
      </v-icon>
      Edition de provider :
      <span v-if="provider?.id">
        {{ provider.id }})
      </span>
    </template>
    
    <template v-slot:content>
      <v-card
        v-if="mounted"
        color="transparent"
        flat
      >
        <v-overlay
          :value="loading"
        >
          <v-progress-circular
            indeterminate
            size="64"
          />
        </v-overlay>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-card>
                <v-card-title>
                  Détail
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="12"
                      md="8"
                      sm="12"
                    >
                      <v-simple-table class="lh_item-return__details">
                        <tbody>
                          <tr>
                            <td>
                              Nom
                            </td>
                            <td>
                              {{ provider.name }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Source
                            </td>
                            <td>
                              {{ $t('views.external_catalog.classifications.enum.sources.' + provider.source) }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Store par défaut
                            </td>
                            <td>
                              <StoreLink :store="provider.default_store" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Date de creation
                            </td>
                            <td>
                              {{ provider.created_at | date }}
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                    <v-spacer />
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="5"
            >
              <v-card>
                <v-card-title>
                  Synchroniser
                </v-card-title>
                <v-card-text>
                  <SyncFromCSV
                    v-if="provider.source==='csv'"
                    :provider="provider.id"
                    @task-created="syncTaskCreated"
                    @task-failed="syncTaskFailed"
                  />
                  <SyncFromShopify
                    v-else-if="provider.source==='shopify'"
                    :provider="provider.id"
                    @task-created="syncTaskCreated"
                    @task-failed="syncTaskFailed"
                  />
                  <div v-else>
                    Source non supportée
                  </div>
                  <SynchroTaskResult
                    v-if="lastSynchroTaskId"
                    :synchro-task-id="lastSynchroTaskId"
                    @changed="reloadSynchroTasks"
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="7"
            >
              <v-card>
                <v-card-title>
                  Historique d'exécution
                </v-card-title>
                <v-card-text>
                  <v-sheet
                    elevation="3"
                    rounded
                  >
                    <v-data-table
                      :footer-props="tableSynchroTask.footerProps"
                      :headers="tableSynchroTask.headers"
                      :items="resultSynchroTask.items"
                      :loading="resultSynchroTask.loading"
                      :options.sync="tableSynchroTask.options"
                      :page="tableSynchroTask.options.page"
                      :server-items-length="resultSynchroTask.count"
                      @update:page="loadSynchroTaskHistory"
                    >
                      <template v-slot:loading>
                        <v-sheet
                          class="text-center pa-6"
                        >
                          {{ $t('global.loading') }}
                        </v-sheet>
                      </template>
                      <template v-slot:no-data>
                        <v-sheet
                          class="text-center pa-6"
                        >
                          {{ $t('global.no-data') }}
                        </v-sheet>
                      </template>
                      <template v-slot:item.date="{ item }">
                        <div style="white-space: nowrap;">
                          {{ item.created_at | date }}
                          <v-tooltip
                            v-if="item.started_at"
                            bottom
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon medium>
                                  mdi-timer-play
                                </v-icon>
                              </v-btn>
                            </template>
                            date de début: {{ item.created_at | date }}
                          </v-tooltip>
                          <v-tooltip
                            v-if="item.ended_at"
                            bottom
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon medium>
                                  mdi-timer-stop
                                </v-icon>
                              </v-btn>
                            </template>
                            date de fin: {{ item.ended_at | date }}
                          </v-tooltip>
                        </div>
                      </template>
                      <template v-slot:item.params="{ item }">
                        <div class="synchro-task-long-result">
                          {{ item.params }}
                        </div>
                      </template>
                      <template v-slot:item.result="{ item }">
                        <div class="synchro-task-long-result">
                          {{ item.error ?? item.result }}
                        </div>
                      </template>
                      <template v-slot:item.status="{ item }">
                        <StatusChip
                          :status="item.status"
                          :config="synchroTaskStatusConfig"
                        />
                      </template>
                    </v-data-table>
                  </v-sheet>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <Snackbar />
    </template>
  </Page>
</template>

<script>
import { getQueryString } from '@/helpers/queryString'
import Page from '@/components/core/Page.vue'
import Snackbar from '@/components/core/Snackbar.vue'
import snackbarMixin from '@/mixins/snackbar'
import StatusChip from '@/components/core/StatusChip.vue'
import StoreLink from '@/components/base/Link/Store.vue'
import SyncFromCSV from '@/views/ExternalCatalog/Providers/SyncFromCSV.vue'
import SyncFromShopify from '@/views/ExternalCatalog/Providers/SyncFromShopify.vue'
import SynchroTaskResult from '@/views/ExternalCatalog/Providers/SynchroTaskResult.vue'

export default {
  name: 'ExternalCatalogProvidersEdit',
  components: {
    Page,
    StoreLink,
    Snackbar,
    StatusChip,
    SyncFromCSV,
    SyncFromShopify,
    SynchroTaskResult,
  },
  mixins: [snackbarMixin],
  data() {
    const currentSynchroTaskPage = +this.$route.query.page || 1
    const synchroTaskItemsPerPage = 30

    return {
      lastSynchroTaskId: null,
      providerId: this.$route.params.id,
      mounted: false,
      request: null,
      loading: false,
      provider: {},
      tableSynchroTask: {
        options: {
          page: currentSynchroTaskPage,
          itemsPerPage: synchroTaskItemsPerPage,
        },
        headers: [
          {
            text: this.$i18n.t('views.external_catalog.synchro_task.headers.date'),
            value: 'date',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.external_catalog.synchro_task.headers.params'),
            value: 'params',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.external_catalog.synchro_task.headers.result'),
            value: 'result',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.external_catalog.synchro_task.headers.status'),
            value: 'status',
            sortable: false,
          },
        ],
        footerProps: {
          'items-per-page-options': [synchroTaskItemsPerPage],
          'show-first-last-page': true,
          'show-current-page': true,
        },
      },
      requestSynchroTask: null,
      resultSynchroTask: {
        items: [],
        count: 0,
        loading: false,
      },
      synchroTaskStatusConfig: {
        error: {
          color: 'error',
          label: 'Erreur',
        },
        waiting: {
          color: 'waiting',
          label: 'En attente',
        },
        pending: {
          color: 'pending',
          label: 'En cours',
        },
        finished: {
          color: 'success',
          label: 'Terminé',
        },
      },
    }
  },
  computed: {
    queryStringSynchroTask: function () {
      return 'external_catalog/synchro_tasks?' + getQueryString(
        this.tableSynchroTask.options.page,
        { provider: this.providerId },
        this.tableSynchroTask.options.sortBy,
        this.tableSynchroTask.options.sortDesc,
        this.tableSynchroTask.options.itemsPerPage
      )
    },
  },
  mounted() {
    this.load()
    this.loadSynchroTaskHistory()
  },
  methods: {
    syncTaskCreated(synchroTaskId) {
      this.snackbarSuccess(this.$i18n.t('views.external_catalog.providers.message.task.success'))
      this.lastSynchroTaskId = synchroTaskId
    },
    syncTaskFailed(synchroTaskId) {
      this.snackbarError(this.$i18n.t('views.external_catalog.providers.message.task.failed'))

      this.lastSynchroTaskId = synchroTaskId
    },
    cancel() {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    load() {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }

      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }

      this.loading = true
      this.$axios.get('external_catalog/providers/' + this.providerId, { cancelToken: axiosSource.token })
        .then((response) => {
          this.provider = response.data
        })
        .catch((error) => {
          const error_message = error.response.data['hydra:description']
              ?? error.response.data['detail']
              ?? 'No context'
          this.snackbarError(this.$i18n.t('views.external_catalog.provider.message.failed', { error: error_message }))
        })
        .finally(() => {
          this.request = null
          this.loading = false
          this.mounted = true
        })
    },

    loadSynchroTaskHistory() {
      if (this.requestSynchroTask) {
        this.request.cancel('aborted')
        this.request = null
      }
      let axiosSource = this.$axios.CancelToken.source()
      this.requestSynchroTask = { cancel: axiosSource.cancel }

      this.resultSynchroTask.loading = true
      this.resultSynchroTask.items = []
      this.resultSynchroTask.count = 0

      this.$axios.get(this.queryStringSynchroTask, {
        cancelToken: axiosSource.token,
      })
        .then((response) => {
          this.resultSynchroTask.items = response.data['hydra:member']
          this.resultSynchroTask.count = response.data['hydra:totalItems']
        })
        .catch((error) => {
          if (!this.$axios.isCancel(error)) {
            // eslint-disable-next-line no-console
            console.log(error)
            this.result.loading = false
          }
        })
        .finally(() => {
          this.requestSynchroTask = null
          this.resultSynchroTask.loading = false
        })
    },
    reloadSynchroTasks () {
      this.tableSynchroTask.options.currentSynchroPage = 1
      this.loadSynchroTaskHistory()
    },
  },
}
</script>
<style scoped>
.synchro-task-long-result {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  font-size: 10px;
}

.synchro-task-long-result:hover {
  cursor: pointer;
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
}
</style>
